<template>
  <div class="uk-grid-medium" uk-grid>
    <div class="uk-width-5-5">
      <div class="d-flex">
        <nav id="breadcrumbs" class="mb-3">
          <ul>
            <li>
              <router-link :to="{ name: 'company.dashboard' }">
                <i class="uil-home-alt"></i>
              </router-link>
            </li>
            <li>
              {{ this.$t('general.programs') }}
            </li>
            <li>{{ this.$t('general.add_program') }}
            </li>
          </ul>
        </nav>
      </div>

      <div class="section-header mb-lg-2 border-0 uk-flex-middle">
        <div class="section-header-left">
          <h2 class="uk-heading-line text-left"><span>{{ this.$t('general.new_program') }}
            </span></h2>
        </div>
      </div>
      <template v-if="errorMessages">
        <div v-for="(error, index) in errorMessages" :key="index" class="uk-alert-danger" uk-alert>
          <a class="uk-alert-close" uk-close></a>
          <p style="color: red">
            <i class="uil-exclamation-triangle"></i> {{ error }}
          </p>
        </div>
      </template>
      <div v-if="successMessage" class="uk-alert-success" uk-alert>
        <a class="uk-alert-close" uk-close></a>
        <p style="color: green">
          <i class="uil-exclamation-triangle"></i> {{ successMessage }}
        </p>
      </div>

      <div class="card">
        <ul class="uk-child-width-expand uk-tab stepper-buttons" id="uk-switcher-tab"
          uk-switcher="connect: #course-edit-tab ; animation: uk-animation-slide-left-medium, uk-animation-slide-right-medium">
          <li class="uk-active">
            <a class="p-4" href="#" aria-expanded="true"><i class="icon-feather-flag"></i> <b>{{ this.$t('general.home')
            }}
              </b></a>
          </li>
          <li class="">
            <a class="p-4" href="#" aria-expanded="false"><i class="uil-book-open"></i> <b>{{ this.$t('general.contents')
            }}
              </b></a>
          </li>
          <li>
            <a class="p-4" href="#" aria-expanded="false"><i class="uil-invoice"></i> <b>{{ this.$t('general.overview') }}
              </b></a>
          </li>
        </ul>

        <form class="card-body" novalidate>
          <ul class="uk-switcher uk-margin" id="course-edit-tab" style="touch-action: pan-y pinch-zoom">
            <!--tab: özellikler-->
            <li class="uk-active" style="">
              <div class="row">
                <div class="col-xl-9 m-auto">
                  <div class="form-group row mb-3">
                    <label class="col-md-3 col-form-label" for="course_title">{{ this.$t('general.program_title') }}
                      <span class="required">*</span></label>
                    <div class="col-md-9">
                      <input v-model="form.title" type="text" class="form-control" id="course_title" name="title"
                        :placeholder="$t('faq.title')" required="" />
                    </div>
                  </div>
                  <div class="form-group row mb-3">
                    <label class="col-md-3 col-form-label" for="short_description">{{ this.$t('messages.description') }}
                      <span class="required">*</span></label>
                    <div class="col-md-9">
                      <textarea name="short_description" id="short_description" class="form-control"
                        v-model="form.description"></textarea>
                    </div>
                  </div>
                </div>
              </div>
              <div class="uk-card-footer float-right pb-0 pr-0">
                <button class="uk-button uk-button-primary small" type="button" @click="validateAndSwitch(1)">
                  <i class="icon-feather-arrow-right"></i> {{ this.$t('general.save_and_continue') }}

                </button>
              </div>
            </li>
            <!--tab: icerik olustur-->
            <li class="" style="">

              <div class="row">
                <div class="col-md-12">
                  <div class="col-xl-12">
                    <button type="button" class="btn btn-secondary mb-3 w-100" @click="createContent()">
                      <i class="uil-plus m-0"></i> {{ this.$t('general.add_chapter') }}

                      <span class="required">*</span>
                    </button>
                  </div>
                  <div id="modal_learning" v-if="showModalLearning" class="uk-modal-full uk-modal" uk-modal="">
                    <div class="uk-modal-footer bggray">
                      <h3 class="float-left">
                        <i class="uil-presentation-plus"></i> İçerik Seç
                      </h3>
                      <button class="
                          uk-button uk-button-default
                          small
                          uk-modal-close
                          float-right
                        " type="button">
                        <i class="uil-times"></i> Kapat
                      </button>
                    </div>

                    <div class="uk-modal-dialog bggray h-100">
                      <Content @addNewEducation="addEducationToContent($event)"
                        @removeEducation="removeEducationFromContent($event)" :selected_items="all_selected_educations"
                        current_page="programs"></Content>
                    </div>
                  </div>

                  <draggable class="row flex-column" style="cursor: move" :list="contents" group="section_widget">
                    <div class="col-xl-12 mb-2" v-for="(content, index) in contents">
                      <ul class="c-curriculum" uk-accordion>
                        <li class="uk-open">
                          <div class="
                              d-flex
                              justify-content-between
                              align-items-center
                            " style="margin-right: -18px;">
                            <div class="row" style="width: 100%;">
                              <div class="col-md-1" style="font-size: 150%;text-align: center;margin-top: 8px;">
                                <i class="uil-folder"> </i>
                              </div>
                              <div class="col-md-4" style="padding:0">
                                <input v-model="content.name" type="text" id="content_name" name="name"
                                  v-on:keydown.enter.prevent placeholder="Bölüm Adı" required="" />
                              </div>
                              <!-- <div class="col-md-6" style="text-align:end">
                                <a class="btn btn-info btn-sm" style="color: #fff" uk-toggle="target: #modal_learning"
                                  @click="active_content_id = content.id">
                                  <i class="uil-plus m-0"> </i>İçerik Seç<span class="required">*</span>
                                </a>
                                <a class="btn btn-info btn-sm ml-1" style="color: #fff"
                                  @click="removeContent(content.id)">
                                  <i class="uil-trash"> </i>
                                </a>
                              </div> -->
                              <div class="col-md-7 " style="text-align: end;">
                                <a class="btn btn-info btn-sm" style="color: #fff" uk-toggle="target: #modal_learning"
                                  @click="active_content_id = content.id">
                                  <i class="uil-plus m-0"> </i>İçerik Seç<span class="required">*</span>
                                </a>
                                <a class="btn btn-info btn-sm ml-1" style="color: #fff"
                                  @click="removeContent(content.id)">
                                  <i class="uil-trash"> </i>
                                </a>
                                <a class="uk-accordion-title" style="display:contents"></a>
                              </div>
                            </div>
                            <!-- <a class="uk-accordion-title" > <i class="uil-folder"> </i>{{ content.name }}
                              </a>
                            <div>
                              <a class="btn btn-info btn-sm" style="color: #fff" uk-toggle="target: #modal_learning"
                                @click="active_content_id = content.id">
                                <i class="uil-plus m-0"> </i>İçerik Seç<span class="required">*</span>
                              </a>
                              <a class="btn btn-info btn-sm ml-1" style="color: #fff" @click="removeContent(content.id)">
                                <i class="uil-trash"> </i>
                              </a>
                            </div> -->
                          </div>
                          <draggable class="uk-accordion-content" :list="content.educations" group="education_widget">
                            <div v-for="education in content.educations">
                              <div class="sec-list-item">
                                <div>
                                  <i class="uil-list-ul mr-2"></i>
                                  <p>{{ education.title }}</p>
                                  <label class="mb-0 mx-2" style="
                                      font-size: 0.75rem;
                                      background: #3e416d;
                                      padding: 1px 5px;
                                      border-radius: 3px;
                                      color: white;
                                    ">{{ $t(education.name) }}</label>
                                </div>
                                <div>
                                  <div class="btn-act">
                                    <!-- <a href="#" uk-tooltip="title:Önizleme"
                                      ><i class="uil-eye"></i
                                    ></a> -->
                                    <a href="#" uk-tooltip="title:Kaldır" @click.prevent="
                                      removeEducationFromContent(
                                        education.id
                                      )
                                      "><i class="icon-feather-trash"></i></a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </draggable>
                        </li>
                      </ul>
                    </div>
                  </draggable>


                  <div class="uk-card-footer float-right pb-0 pr-0">
                    <button class="uk-button uk-button-outline small mr-2" type="button" @click="switcherChange(0)">
                      <i class="icon-feather-arrow-left"></i> {{ this.$t('general.previous') }}

                    </button>
                    <button class="uk-button uk-button-primary small" type="button" @click="validateAndSwitch(2)">
                      <i class="icon-feather-arrow-right"></i> {{ this.$t('general.save_and_continue') }}

                    </button>
                  </div>
                </div>
              </div>
            </li>
            <!-- Result -->
            <li>
              <div class="row">
                <div class="col-12 my-lg-5">
                  <div class="text-center">
                    <h2 class="mt-0">
                      <i class="icon-feather-check-circle text-success"></i>
                    </h2>
                    <h3 class="mt-0">{{ this.$t('general.program_is_ready') }}</h3>

                    <div class="col-md-6 offset-md-3">
                      <table class="uk-table uk-table-divider result-table">
                        <tbody>
                          <tr>
                            <td>
                              Program Başlığı: <b>{{ form.title }}</b>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Toplam Bölüm: <b>{{ contents.length }}</b>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Toplam İçerik: <b>{{ all_selected_educations.length }}</b>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              <div class="uk-card-footer float-right pb-0 pr-0">
                <button class="uk-button uk-button-outline mr-2" type="button" @click="switcherChange(1)">
                  <i class="icon-feather-arrow-left"></i>{{ this.$t('general.previous') }}

                </button>
                <button class="uk-button uk-button-primary small" type="button" @click="saveForm()">
                  <i class="icon-feather-arrow-right"></i> {{ this.$t('collectionDetail.save') }}

                </button>
              </div>
            </li>
          </ul>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import store from "@/core/services";
import draggable from "vuedraggable";
import moment from "moment";
import Swal from "sweetalert2";
import Content from "@//view/components/assignment/Content";
import module, {
  MODULE_NAME,
  CREATE_ITEM,
  BASE_URL,
  ERROR,
} from "@/core/services/store/program.module";

export default {
  name: "Contents",
  components: { Content, draggable },
  data() {
    return {
      showModalLearning: false,
      successMessage: "",
      errorMessages: [],
      all_selected_educations: [],
      contents: [],
      educations: [],
      form: {
        title: "",
        description: "",
      },
    };
  },
  beforeCreate() {
    function registerStoreModule(moduleName, storeModule) {
      if (!(store && store.state && store.state[moduleName])) {
        store.registerModule(moduleName, storeModule);
      }
    }
    registerStoreModule(MODULE_NAME, module);
  },
  computed: {
    error: {
      get() {
        return store.getters[MODULE_NAME + "/" + ERROR];
      },
      set(value) { },
    },
  },
  methods: {
    saveForm() {
      let self = this;
      let form = self.form;
      if (!self.isValidFields("ALL")) return false;

      let formData = new FormData();
      formData.append("title", form.title);
      formData.append("description", form.description);


      self.contents.forEach((section, section_index) => {
        let count = 0
        section.educations.forEach((education, index) => {
          count++
          formData.append("contents[" + section_index + "][educations][" + index + "]", education.id);
        })
        if (count > 0)
          formData.append("contents[" + section_index + "][name]", section.name);
      });


      store
        .dispatch(MODULE_NAME + "/" + CREATE_ITEM, {
          url: BASE_URL,
          contents: formData,
        })
        .then(() => {
          $("html, body").animate({ scrollTop: 0 }, "slow");
          if (!self.error) {
            self.successMessage = self.$t("general.successfully_created");
            self.$router.push({ name: "company.programs.all" });
          } else self.errorMessages.push(self.error);
        });
    },
    createContent() {
      Swal.fire({
        title: "Bölüm Adı",
        input: "text",
        inputAttributes: {
          autocapitalize: "off",
        },
        showCancelButton: true,
        confirmButtonText: "Oluştur",
        confirmButtonColor: "#ae71ff",
        cancelButtonText: "Vazgeç",
        showLoaderOnConfirm: true,
        allowOutsideClick: () => !Swal.isLoading(),
        preConfirm: (value) => {
          if (!value) {
            Swal.showValidationMessage(
              this.$t("validation.required_field").replace(":NAME", "Bölüm Adı")
            );
          }
        },
      }).then((result) => {
        if (result.isConfirmed && result.value) {
          let length = this.contents.length;
          this.contents.push({
            id: length + 1,
            name: result.value,
            educations: [],
          });
        }
      });
    },
    addEducationToContent(education_item) {
      let self = this;
      let edu_id = education_item.id;
      let content_id = self.active_content_id;
      if (self.active_content_id == null) return false;
      self.contents.forEach(function (item, index) {
        if (item.id == content_id) {
          self.all_selected_educations.push(edu_id);
          self.contents[index].educations.push(education_item);
        }
      });
    },
    removeEducationFromContent(edu_id) {
      let content_index = null
      for (const [index, value] of Object.entries(this.contents)) {
        content_index = value.educations
          .map(function (x) {
            return x.id;
          })
          .indexOf(edu_id);
        if (content_index != -1) {
          content_index = index
          break
        }
      };

      let educations_of_content = this.contents[content_index].educations;
      let educations_ids_all = this.all_selected_educations;
      let pos = educations_of_content
        .map(function (x) {
          return x.id;
        })
        .indexOf(edu_id);
      let pos_all = educations_ids_all
        .map(function (x) {
          return x;
        })
        .indexOf(edu_id);
      educations_of_content.splice(pos, 1);
      educations_ids_all.splice(pos_all, 1);
      return true;
    },
    removeContent(id) {
      let index = this.contents
        .map(function (x) {
          return x.id;
        })
        .indexOf(id);
      if (this.contents[index].educations)
        this.contents[index].educations.forEach((element) => {
          let pos = this.educations
            .map(function (x) {
              return x.id;
            })
            .indexOf(element);
          let pos_all = this.all_selected_educations
            .map(function (x) {
              return x;
            })
            .indexOf(element);
          this.educations.splice(pos, 1);
          this.all_selected_educations.splice(pos_all, 1);
        });
      this.contents.splice(index, 1);
    },
    resetMessages() {
      this.successMessage = null;
      this.errorMessages = [];
    },
    isValidFields(fields) {
      let self = this;
      let form = self.form;
      self.resetMessages();
      if (fields != "ALL") fields--;

      if (fields == "ALL" || fields == 0) {
        if (form.title.length === 0) {
          return self.showFieldsFailedError(
            0,
            self
              .$t("validation.required_field")
              .replace(":NAME", "Program Başlığı")
          );
        }
        if (form.description.length == 0) {
          return self.showFieldsFailedError(
            0,
            self.$t("validation.required_field").replace(":NAME", "Açıklama")
          );
        }
      }
      if ((fields == "ALL" || fields == 1) && self.all_selected_educations.length == 0) {
        return self.showFieldsFailedError(1, self.$t("validation.required_field").replace(":NAME", "{{this.$t('messages.content')}}"));
      }

      return true;
    },
    showFieldsFailedError(index, message) {
      this.errorMessages.push(message);
      this.scrollToTop();
      this.switcherChange(index);
      return false;
    },
    validateAndSwitch(index) {
      if (this.isValidFields(index)) this.switcherChange(index);
    },
    switcherChange(index) {
      UIkit.switcher($("#uk-switcher-tab")[0]).show(index);
    },
    scrollToTop() {
      window.scroll({
        top: 0,
        behavior: "smooth",
      });
    },
  },
  mounted() {
    this.all_selected_educations = [];
    this.$forceUpdate();
    setTimeout(() => {
      this.showModalLearning = true;
    }, 1000);
  },
  beforeDestroy() {
    const modal = UIkit.modal("#modal_learning");
    if (modal) modal.$destroy(true);
  },
  watch: {
    successMessage: function (val) {
      this.debouncedResetValues();
    },
    errorMessages: function (val) {
      this.debouncedResetValues();
    },
  },
  created: function () {
    this.debouncedResetValues = _.debounce(this.resetMessages, 2500);
  },
};
</script>
<style scoped>
input[type="button"],
input[type="submit"],
span.btn,
.btn {
  display: inline-block;
}
</style>
